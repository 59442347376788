import { DropTargetMonitor } from 'react-dnd';

import {
  ComponentDSLNameTypes,
  ComponentListDSL,
  NodeDSL,
  NodeID,
  NodeListDSL,
  nodeListSelectors,
  nodeSelectors,
} from '@builder/schemas';
import { isArray } from '@builder/utils';

import {
  ComponentMoveMeta,
  dashboardSelectors,
  DashboardState,
  isValidTargetToMove,
} from 'src/store';
import { assertIsNotPage } from 'src/store/dashboard/utils';

import { DRAGGABLES } from './constants';
import { DraggableItem } from './types';

export const isValidPathWithSchemaCheck = ({
  nodeListDSL,
  componentListDSL,
  itemID,
  itemName,
  intoNode,
  intoTargetPropName,
}: {
  nodeListDSL: NodeListDSL;
  componentListDSL: ComponentListDSL;
  itemID: NodeID;
  itemName: ComponentDSLNameTypes;
  intoNode: NodeDSL;
  intoTargetPropName: Array<string | number> | null;
}): boolean => {
  if (intoTargetPropName === null) {
    return false;
  }

  if (!isValidTargetToMove(nodeListDSL, itemID, intoNode.id)) {
    return false;
  }

  const itemSchema = componentListDSL[itemName];
  if (
    nodeListSelectors.isAllowedToPutNodeInto(nodeListDSL, {
      componentListDSL,
      targetNodeSchema: itemSchema,
      intoNodeID: intoNode.id,
      propName: intoTargetPropName,
    })
  ) {
    return true;
  }

  return false;
};

/**
 * @deprecated check canDropOn locally for every slot (do not use monitors since they are global!!!)
 */
export const canDropOn = (
  spec: Omit<ComponentMoveMeta, 'sourceID' | 'kind'>,
  state: DashboardState,
) => (item: DraggableItem, _monitor: DropTargetMonitor): boolean => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
  const isOverlayItem = isArray(item);

  let intoNode: NodeDSL;
  let intoTargetPropName: Array<string | number> | null;
  let finalTargetID;
  if (spec.type === 'into') {
    intoNode = nodeListDSL[spec.target.nodeID];
    intoTargetPropName = spec.target.propName;
    finalTargetID = spec.target.nodeID;
  } else {
    finalTargetID = assertIsNotPage(nodeListDSL[spec.target.nodeID].parentID);
    intoNode = nodeListDSL[finalTargetID];
    const intoNodeSchema = componentListDSL[intoNode.name].schema.props;
    const intoNodeProps = intoNode.props;
    intoTargetPropName = nodeSelectors.findNodePlacedPropNameInParent(
      intoNodeSchema,
      intoNodeProps,
      spec.target.nodeID,
    );
  }

  // OverlayItem
  if (isOverlayItem) {
    const validations = item.map(draggableItem =>
      isValidPathWithSchemaCheck({
        nodeListDSL,
        componentListDSL,
        itemID: draggableItem.id,
        itemName: draggableItem.name,
        intoNode,
        intoTargetPropName,
      }),
    );

    return validations.every(bool => bool === true);
  }

  // Icon
  if (
    !isValidPathWithSchemaCheck({
      nodeListDSL,
      componentListDSL,
      itemID: item.id,
      itemName: item.meta.name,
      intoNode,
      intoTargetPropName,
    })
  ) {
    return false;
  }

  return item.type === DRAGGABLES.ICON;
};

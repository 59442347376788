/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable radix */
import { isEmpty, pathOr } from 'ramda';

import {
  addAliasToAllNodes,
  AppDSL,
  ComponentDSL,
  ComponentListDSL,
  componentListSelectors,
  hasPropJsCode,
  NodeDSL,
  ClonedNodeDSL,
  NodeID,
  NodeListDSL,
  nodeListSelectors,
  NodeStateConnectionDSL,
  PropAssert,
  PropChecker,
  ReactNodePropValue,
  ReplacedStateArrayDSL,
  replaceJSInjectionWithNewStates,
  ClonedStateDSL,
  StateListDSL,
  stateListSelectors,
  ObjectPropValue,
  StateDSL,
  isDialogComponent,
  StateDSLWithDialog,
  isLocalDialogComponent,
  COMPONENT_DSL_NAMES,
} from '@builder/schemas';
import {
  SystemError,
  ERROR_SCOPES,
  isUndefined,
  generateID,
  incrementName,
  isArray,
} from '@builder/utils';

import { dashboardSelectors, DashboardState } from 'src/store';

import { RESERVED_WORDS } from './constants';

export const copyNodeWithChildren = ({
  nodeDSL,
  nodeListDSL,
  componentListDSL,
  stateListDSL,
  replacedStatesList: initialReplacedStatesList,
  nestedStates,
  boundedStates: initialBoundedStates,
  isCopySet = false,
  rootRouteNodeDSL,
  isDialogTarget,
  isLocalDialogTarget,
  isDialogBufferNodes,
  isCloning,
}: {
  nodeDSL: NodeDSL;
  nodeListDSL: NodeListDSL;
  componentListDSL: ComponentListDSL;
  stateListDSL: StateListDSL;
  replacedStatesList: StateListDSL;
  nestedStates?: NodeStateConnectionDSL[];
  boundedStates?: NodeStateConnectionDSL[];
  isCopySet?: boolean;
  rootRouteNodeDSL?: NodeDSL;
  isDialogTarget?: string | undefined;
  isLocalDialogTarget?: string | undefined;
  isDialogBufferNodes?: string | undefined;
  isCloning?: boolean;
}): {
  nodeDSL: NodeDSL;
  childrenNodes: NodeDSL[];
  newStates: StateListDSL;
  newBoundedStates: NodeStateConnectionDSL[];
} => {
  const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
    componentName: nodeDSL.name,
  });
  const newID = isCopySet ? nodeDSL.id : generateID();

  let localDialogTarget = isLocalDialogTarget;
  if (
    componentDSL.name === COMPONENT_DSL_NAMES.LocalDialogSymbol &&
    nodeDSL?.name === COMPONENT_DSL_NAMES.LocalDialogSymbol
  ) {
    localDialogTarget = newID;
  }

  const { replacedStatesList } = getNodeStates(
    nodeDSL,
    stateListDSL,
    nestedStates,
    rootRouteNodeDSL,
    isDialogTarget,
    localDialogTarget,
    newID,
    isDialogBufferNodes,
    isCloning,
  );
  const newBoundedStates = [] as NodeStateConnectionDSL[];
  if (!isEmpty(initialBoundedStates) && !isEmpty(Object.entries(replacedStatesList))) {
    initialBoundedStates?.forEach(oldState => {
      Object.values(replacedStatesList).forEach((newState: ClonedStateDSL) => {
        if (newState.oldID === oldState.stateID) {
          newBoundedStates.push({
            ...oldState,
            stateID: newState.id,
          });
        }
      });
    });
  }

  const {
    transformedNodeProps,
    childrenNodes,
    replacedStatesList: previousReplacedStatesList,
    boundedStates: previousBoundedStates,
  } = getNodeProps({
    oldParentID: nodeDSL.parentID || '',
    parentID: isCopySet ? nodeDSL.parentID || '' : newID,
    nodeProps: nodeDSL.props,
    propsSchema: componentDSL.schema.props,
    replacedStatesList: { ...initialReplacedStatesList, ...replacedStatesList },
    nodeListDSL,
    componentListDSL,
    stateListDSL,
    boundedStates: [...(initialBoundedStates || []), ...newBoundedStates],
  });

  const newNodeDSL: ClonedNodeDSL = {
    ...nodeDSL,
    id: newID,
    props: transformedNodeProps,
    oldID: nodeDSL.id,
  };

  if (!isEmpty(newBoundedStates)) {
    newBoundedStates?.forEach((oldState, index) => {
      Object.values({ [newNodeDSL.id]: newNodeDSL, ...childrenNodes } as ClonedNodeDSL[]).forEach(
        (newNode: ClonedNodeDSL) => {
          if (newNode.oldID === oldState.componentBoundID) {
            newBoundedStates[index] = {
              ...oldState,
              componentBoundID: newNode.id,
            };
          }
        },
      );
    });
  }

  const mergedBoundedStates = [
    ...(initialBoundedStates || []),
    ...(previousBoundedStates || []),
    ...newBoundedStates,
  ];

  const mergedReplacedStatesList: StateListDSL = {
    ...initialReplacedStatesList,
    ...previousReplacedStatesList,
    ...replacedStatesList,
  };

  return {
    nodeDSL: newNodeDSL as NodeDSL,
    childrenNodes,
    newStates: mergedReplacedStatesList,
    newBoundedStates: mergedBoundedStates,
  };
};

const getReplacedStateArrayDSL = (replacedStateListDSL: StateListDSL): ReplacedStateArrayDSL => {
  return Object.entries(replacedStateListDSL).reduce(
    (replacedStates, [previousName, replacedStateDSL]) => {
      replacedStates.push({
        newName: replacedStateDSL.name,
        previousName,
      });
      return replacedStates;
    },
    [] as ReplacedStateArrayDSL,
  );
};

const getNodeProps = ({
  oldParentID,
  parentID,
  nodeProps,
  propsSchema,
  replacedStatesList,
  nodeListDSL,
  componentListDSL,
  stateListDSL,
  boundedStates,
}: {
  oldParentID: NodeID;
  parentID: NodeID;
  nodeProps: NodeDSL['props'];
  propsSchema: ComponentDSL['schema']['props'];
  replacedStatesList: StateListDSL;
  nodeListDSL: NodeListDSL;
  componentListDSL: ComponentListDSL;
  stateListDSL: StateListDSL;
  boundedStates?: NodeStateConnectionDSL[];
}): {
  transformedNodeProps: NodeDSL['props'];
  childrenNodes: NodeDSL[];
  replacedStatesList: StateListDSL;
  boundedStates?: NodeStateConnectionDSL[];
} => {
  const childrenNodes: NodeDSL[] = [];
  let mergedReplacedStatesList: StateListDSL = { ...replacedStatesList };
  const mergedBoundedStates: NodeStateConnectionDSL[] = boundedStates || [];
  const validateJsProp = (str: string, arr: string[]) => {
    // This regular expression matches any non-space, non-period character that appears either at the beginning of the string,
    // after a space or opening parenthesis, or after a period, and is followed by either a space, a period, or the end of the string.
    const regex = /(?:^|\s|\()([^.\s()]+)(?=\s*\(|\.|$)/g;
    let match;
    const result: string[] = [];
    while ((match = regex.exec(str))) {
      const word = match[1];
      if (!word.includes('.')) {
        result.push(word);
      }
    }

    return result.every(word => arr.includes(word));
  };

  const transformedNodeProps = Object.keys(propsSchema).reduce((accum, propName) => {
    const propSchema = propsSchema[propName];
    const propValue = nodeProps[propName];

    if (propName === 'fieldProps') {
      const parentFormNode = nodeListSelectors.getNodeDSL(nodeListDSL, {
        nodeID: oldParentID,
      });
      const childrenIDs = nodeListSelectors.getAllChildrenIDs(nodeListDSL, {
        nodeID: parentFormNode.id,
        componentListDSL,
      });
      const childrenFieldNames = childrenIDs
        .map(childID => nodeListDSL[childID])
        .filter(childrenNode => Object.hasOwn(childrenNode.props, 'fieldProps'))
        .map(childrenNode => (childrenNode.props.fieldProps as ObjectPropValue)?.name);

      const newFieldName = incrementName({
        nameToIncrement: (propValue as ObjectPropValue)?.name as string,
        dictionary: childrenFieldNames as string[],
        delimiter: '_',
      });

      return {
        ...accum,
        [propName]: {
          name: newFieldName,
        },
      };
    }

    if (isUndefined(propValue)) {
      return accum;
    }

    const stateNames = Object.values(stateListDSL ?? {}).map(state => state.name);

    if (hasPropJsCode(propValue)) {
      const replacedStateListDSL = getReplacedStateArrayDSL(mergedReplacedStatesList);
      const withReplacedLocalStates = replaceJSInjectionWithNewStates(
        propValue,
        replacedStateListDSL,
      );

      return {
        ...accum,
        [propName]: withReplacedLocalStates,
      };
    }

    if (
      PropChecker.Schema.isActionProp(propSchema) &&
      PropChecker.Value.isActionPropWithArgs(propValue)
    ) {
      const transformedActionArgs = Object.entries(propValue.args).reduce(
        (acc, [argName, argValue]) => {
          if (hasPropJsCode(argValue)) {
            const replacedStateListDSL = getReplacedStateArrayDSL(mergedReplacedStatesList);
            const withReplacedLocalStates = replaceJSInjectionWithNewStates(
              argValue,
              replacedStateListDSL,
            );

            return { ...acc, [argName]: withReplacedLocalStates };
          }

          if (
            argValue &&
            typeof argValue === 'string' &&
            Object.keys(mergedReplacedStatesList).some(oldState =>
              (argValue as string).includes(oldState),
            )
          ) {
            const replacedStateListDSL = getReplacedStateArrayDSL(mergedReplacedStatesList);
            const withReplacedLocalStates = replaceJSInjectionWithNewStates(
              argValue,
              replacedStateListDSL,
            );

            if (!validateJsProp(argValue, [...stateNames, ...RESERVED_WORDS]))
              return { ...acc, [argName]: '' };

            return { ...acc, [argName]: withReplacedLocalStates };
          }

          if (
            argValue &&
            typeof argValue === 'string' &&
            Object.keys(mergedReplacedStatesList).some(oldState =>
              (argValue as string).includes(oldState),
            )
          ) {
            const replacedStateListDSL = getReplacedStateArrayDSL(mergedReplacedStatesList);
            const withReplacedLocalStates = replaceJSInjectionWithNewStates(
              argValue,
              replacedStateListDSL,
            );

            return { ...acc, [argName]: withReplacedLocalStates };
          }

          return { ...acc, [argName]: argValue };
        },
        {},
      );
      return {
        ...accum,
        [propName]: {
          ...propValue,
          args: transformedActionArgs,
        },
      };
    }

    if (PropChecker.Schema.isObjectPropWithDefinedProps(propSchema)) {
      const {
        transformedNodeProps: childrenNodeProps,
        childrenNodes: grandChildrenNodes,
        replacedStatesList: childrenReplacedStatesList,
        boundedStates: childrenBoundedstates,
      } = getNodeProps({
        oldParentID,
        parentID,
        propsSchema: propSchema.props,
        nodeProps: nodeProps[propName] as NodeDSL['props'],
        replacedStatesList: mergedReplacedStatesList,
        nodeListDSL,
        componentListDSL,
        stateListDSL,
        boundedStates: mergedBoundedStates,
      });
      mergedReplacedStatesList = { ...mergedReplacedStatesList, ...childrenReplacedStatesList };
      childrenNodes.push(...grandChildrenNodes);
      mergedBoundedStates.push(...(childrenBoundedstates || []));
      return {
        ...accum,
        [propName]: childrenNodeProps,
      };
    }

    if (
      PropChecker.Schema.isObjectPropWithNoDefinedProps(propSchema) &&
      PropChecker.Value.isObjectProp(propValue)
    ) {
      const transformedPropValue = Object.entries(propValue).reduce(
        (transformedObject, [key, value]) => {
          if (hasPropJsCode(value)) {
            const replacedStateListDSL = getReplacedStateArrayDSL(mergedReplacedStatesList);
            const withReplacedLocalStates = replaceJSInjectionWithNewStates(
              value,
              replacedStateListDSL,
            );

            return {
              ...transformedObject,
              [key]: withReplacedLocalStates,
            };
          }

          return {
            ...transformedObject,
            [key]: value,
          };
        },
        {},
      );
      return {
        ...accum,
        [propName]: transformedPropValue,
      };
    }

    if (PropChecker.Schema.isArrayPropWithDefinedObjectProps(propSchema)) {
      const arrayPropSchema: ComponentDSL['schema']['props'] = propSchema.item.props;
      const arrayPropValue = nodeProps[propName];
      PropAssert.Value.assertIsArrayProp(arrayPropValue, propName);
      const childrenNodeArrayProps = arrayPropValue.map(arrayPropItemValue => {
        const {
          transformedNodeProps: childrenNodeProps,
          childrenNodes: grandChildrenNodes,
          replacedStatesList: childrenReplacedStatesList,
          boundedStates: childrenBoundedstates,
        } = getNodeProps({
          oldParentID,
          parentID,
          propsSchema: arrayPropSchema,
          nodeProps: arrayPropItemValue,
          replacedStatesList: mergedReplacedStatesList,
          nodeListDSL,
          componentListDSL,
          stateListDSL,
          boundedStates: mergedBoundedStates,
        });
        mergedReplacedStatesList = { ...mergedReplacedStatesList, ...childrenReplacedStatesList };
        childrenNodes.push(...grandChildrenNodes);
        mergedBoundedStates.push(...(childrenBoundedstates || []));
        return childrenNodeProps;
      });
      return {
        ...accum,
        [propName]: childrenNodeArrayProps,
      };
    }

    if (
      PropChecker.Schema.isRenderableProp(propSchema) &&
      PropChecker.Value.isRenderableNodesProp(propValue)
    ) {
      const transformedChildrenNodes = propValue.nodes.map(childrenNodeID => {
        const childrenNode = nodeListSelectors.getNodeDSL(nodeListDSL, {
          nodeID: childrenNodeID,
        });
        const {
          nodeDSL: childrenNodeDSL,
          childrenNodes: grandChildrenNodes,
          newStates,
          newBoundedStates,
        } = copyNodeWithChildren({
          nodeDSL: childrenNode,
          nodeListDSL,
          componentListDSL,
          stateListDSL,
          replacedStatesList: mergedReplacedStatesList,
          boundedStates,
        });
        mergedReplacedStatesList = {
          ...mergedReplacedStatesList,
          ...newStates,
        };
        mergedBoundedStates.push(...newBoundedStates);
        const updatedChildrenNodeDSL = {
          ...childrenNodeDSL,
          parentID,
          oldID: childrenNode.id,
        };
        childrenNodes.push(updatedChildrenNodeDSL, ...grandChildrenNodes);
        return updatedChildrenNodeDSL;
      });
      return {
        ...accum,
        [propName]: {
          ...propValue,
          nodes: transformedChildrenNodes.map(({ id }) => id),
        },
      };
    }

    if (PropChecker.Value.isObjectProp(propValue)) {
      const transformedPropValue = Object.entries(propValue).reduce(
        (transformedObject, [key, value]) => {
          if (hasPropJsCode(value)) {
            const replacedStateListDSL = getReplacedStateArrayDSL(mergedReplacedStatesList);
            const withReplacedLocalStates = replaceJSInjectionWithNewStates(
              value,
              replacedStateListDSL,
            );

            return {
              ...transformedObject,
              [key]: withReplacedLocalStates,
            };
          }

          return {
            ...transformedObject,
            [key]: value,
          };
        },
        {},
      );
      return {
        ...accum,
        [propName]: transformedPropValue,
      };
    }

    return {
      ...accum,
      [propName]: propValue,
    };
  }, {});
  return { transformedNodeProps, childrenNodes, replacedStatesList: mergedReplacedStatesList };
};

const getNodeStates = (
  nodeDSL: NodeDSL,
  oldStateListDSL: StateListDSL,
  nestedStates?: NodeStateConnectionDSL[],
  rootRouteNodeDSL?: NodeDSL,
  isDialogTarget?: string | undefined,
  isLocalDialogTarget?: string | undefined,
  newID?: string | undefined,
  isDialogBufferNodes?: string | undefined,
  isCloning?: boolean,
): { nodeStates: NodeStateConnectionDSL[]; replacedStatesList: StateListDSL } => {
  const replacedStatesList: StateListDSL = {};
  const nodeStates = pathOr<NodeStateConnectionDSL[]>([], ['states'], nodeDSL);
  const allNodeStates = (nestedStates ? [...nodeStates, ...nestedStates] : nodeStates)?.reduce(
    (uniqueObjects: NodeStateConnectionDSL[], currentObject) => {
      const exists = uniqueObjects.some(
        object =>
          object.stateID === currentObject.stateID &&
          object.required === currentObject.required &&
          object.componentBoundID === currentObject.componentBoundID,
      );

      if (!exists) {
        uniqueObjects.push(currentObject);
      }

      return uniqueObjects;
    },
    [],
  );

  let newStateList: StateListDSL = oldStateListDSL;
  const replacedNodeStates = allNodeStates.map(state => {
    const boundId =
      (isDialogTarget || isDialogBufferNodes || isLocalDialogTarget) && newID ? newID : undefined;
    const stateDSL = newStateList[state.stateID] as StateDSL & { defaultValue?: unknown };
    if (
      !stateDSL ||
      (!stateListSelectors.isLocalStateDSL(newStateList, { id: state.stateID }) &&
        !isDialogTarget &&
        !isLocalDialogTarget &&
        !isDialogBufferNodes)
    ) {
      return state;
    }

    const newStateID = generateID();
    const newStateName = stateListSelectors.calculateStateName(newStateList, {
      name: stateDSL.name,
    });
    const getStateScope = () => {
      if (isDialogTarget && !isDialogBufferNodes) return 'global';

      if (isLocalDialogTarget || (!isDialogTarget && isDialogBufferNodes)) return 'local';

      return stateDSL.scope;
    };

    const getDefaultValue = (value: unknown) => {
      const type = typeof value;
      const hasArray = isArray(value);
      if (hasArray) {
        return [];
      }

      switch (type) {
        case 'string':
          return '';
        case 'number':
          return 1;
        case 'object':
          return stateDSL.name.toLowerCase().includes('autocomplete')
            ? { label: '', value: '' }
            : {};
        case 'boolean':
          return false;

        default:
          return undefined;
      }
    };

    const newStateDSL = {
      ...stateDSL,
      name: newStateName,
      id: newStateID,
      parent: rootRouteNodeDSL?.id ?? stateDSL.parent,
      oldID: stateDSL.id,
      dialogBoundID: boundId,
      componentBoundID: boundId,
      scope: getStateScope(),
      defaultValue:
        state.required && state.componentBoundID
          ? getDefaultValue(stateDSL?.defaultValue)
          : stateDSL?.defaultValue,
    } as ClonedStateDSL;
    const newNodeStateConnectionDSL: NodeStateConnectionDSL = {
      ...state,
      stateID: newStateID,
    };

    if (!isLocalDialogTarget || stateDSL.variant !== 'open-close' || isCloning) {
      replacedStatesList[stateDSL.name] = newStateDSL;
    }

    newStateList = {
      ...newStateList,
      [newStateDSL.id]: newStateDSL,
    };
    return newNodeStateConnectionDSL;
  });

  return { nodeStates: replacedNodeStates, replacedStatesList };
};

/**
 * @example
 * {
 *   stateName: {
 *     id: 'someID',
 *     name: 'stateName',
 *   },
 * }
 * =>
 * {
 *   someID: {
 *     id: 'someID',
 *     name: 'stateName',
 *   },
 * }
 * */
export const reduceStateListWithIDs = (stateListDSL: StateListDSL): StateListDSL => {
  return Object.entries(stateListDSL).reduce((acc, [stateName, stateDSL]) => {
    return {
      ...acc,
      [stateDSL.id]: stateDSL,
    };
  }, {} as StateListDSL);
};

export const componentCopy = (
  state: DashboardState,
  nodeID: NodeID,
  currentPathName?: string,
): DashboardState => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const stateListDSL = dashboardSelectors.getStateListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
  const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID,
  });

  const rootRouteNodeDSL = Object.values(nodeListDSL).filter(
    node => node.props.path === currentPathName,
  )[0] as NodeDSL;

  const localStateList = rootRouteNodeDSL.context as StateListDSL;

  const childrenIDs = nodeListSelectors.getAllChildrenIDs(nodeListDSL, {
    nodeID,
    componentListDSL,
  });

  if (!targetNodeDSL.parentID) {
    throw new SystemError(
      ERROR_SCOPES.dashboard,
      `Parent ID not found for the target ${JSON.stringify(targetNodeDSL)}`,
    );
  }

  const nestedStates =
    rootRouteNodeDSL?.states?.filter(states =>
      [...childrenIDs, targetNodeDSL.id].includes(states.componentBoundID as string),
    ) ?? [];
  const globalNestedStates =
    Object.values(stateListDSL ?? {})
      .filter(stateDSL =>
        [...childrenIDs, targetNodeDSL.id].includes(
          (stateDSL as StateDSLWithDialog).componentBoundID as string,
        ),
      )
      ?.map(globalState => {
        return {
          stateID: globalState.id,
          required: true,
          componentBoundID: (globalState as StateDSLWithDialog).componentBoundID,
        };
      }) ?? [];
  const globalBoundedStates =
    Object.values(stateListDSL ?? {})?.map(globalState => {
      return {
        stateID: globalState.id,
        required: true,
        componentBoundID: (globalState as StateDSLWithDialog).componentBoundID,
      };
    }) ?? [];
  const isGlobalDialogTarget = isDialogComponent(targetNodeDSL.id, nodeListDSL);
  const isLocalDialogTarget = isLocalDialogComponent(targetNodeDSL.id, nodeListDSL);
  const {
    nodeDSL: newNodeDSL,
    newStates,
    childrenNodes: newChildrenNodes,
    newBoundedStates,
  } = copyNodeWithChildren({
    nodeDSL: targetNodeDSL,
    nodeListDSL,
    componentListDSL,
    stateListDSL: { ...localStateList, ...stateListDSL },
    replacedStatesList: {},
    nestedStates: [...nestedStates, ...globalNestedStates],
    boundedStates: [...(rootRouteNodeDSL.states ?? []), ...globalBoundedStates],
    isDialogTarget: isGlobalDialogTarget,
    isLocalDialogTarget,
    isCloning: true,
  });
  Object.keys(newChildrenNodes).forEach(nodeKey => {
    delete (newChildrenNodes[parseInt(nodeKey)] as ClonedNodeDSL).oldID;
  });
  Object.keys(newStates).forEach(stateKey => {
    delete (newStates[stateKey] as ClonedStateDSL).oldID;
  });
  delete (newNodeDSL as ClonedNodeDSL).oldID;

  const newContext = newStates ? reduceStateListWithIDs(newStates) : rootRouteNodeDSL.context;
  const childrenNodesList = newChildrenNodes.reduce((accum, nodeDSLWithoutID) => {
    return {
      ...accum,
      [nodeDSLWithoutID.id]: nodeDSLWithoutID,
    };
  }, {} as NodeListDSL);

  const routeRouteNodeID = rootRouteNodeDSL.id;
  const parentNodeID = targetNodeDSL.parentID as NodeID;
  const newNodeID = newNodeDSL.id;
  const statesToBounded = newBoundedStates.reduce(
    (accum: NodeStateConnectionDSL[], stateDSL: NodeStateConnectionDSL) => {
      const allContext = {
        ...nodeListDSL[routeRouteNodeID].context,
        ...newContext,
      };

      if (!allContext[stateDSL.stateID]) {
        return accum;
      }

      return [...accum, stateDSL];
    },
    [],
  );

  if (isGlobalDialogTarget) {
    const rootGlobalDialog = [];
    const rootChildren = [];
    newNodeDSL.name === COMPONENT_DSL_NAMES.DialogSymbol
      ? rootGlobalDialog.push(newNodeID)
      : rootChildren.push(newNodeID);

    const newUserAppDSL: AppDSL = {
      ...state.appConfiguration.appDSL,
      nodes: addAliasToAllNodes({
        nodeListDSL: {
          ...nodeListDSL,
          [parentNodeID]: {
            ...nodeListDSL[parentNodeID],
            props: {
              ...nodeListDSL[parentNodeID].props,
              dialogs: {
                nodes: [
                  ...(nodeListDSL[parentNodeID].props.dialogs as ReactNodePropValue)?.nodes,
                  ...rootGlobalDialog,
                ],
              },
              children: {
                nodes: [
                  ...(nodeListDSL[parentNodeID].props.children as ReactNodePropValue)?.nodes,
                  ...rootChildren,
                ],
              },
            },
          },
          [newNodeID]: newNodeDSL,
          ...childrenNodesList,
        },
        updateAliasForNodes: {
          [newNodeID]: newNodeDSL,
          ...childrenNodesList,
        },
        componentListDSL,
      }),
    };

    return {
      ...state,
      appConfiguration: {
        ...state.appConfiguration,
        appDSL: {
          ...newUserAppDSL,
          states: { ...state.appConfiguration.appDSL.states, ...newContext },
        },
      },
    };
  }

  const newUserAppDSL: AppDSL = {
    ...state.appConfiguration.appDSL,
    nodes: addAliasToAllNodes({
      nodeListDSL: {
        ...nodeListDSL,
        [routeRouteNodeID]: {
          ...nodeListDSL[routeRouteNodeID],
          context: {
            ...nodeListDSL[routeRouteNodeID].context,
            ...newContext,
          },
          states: [...statesToBounded],
        },
        [parentNodeID]: {
          ...nodeListDSL[parentNodeID],
          props: {
            ...nodeListDSL[parentNodeID].props,
            children: {
              nodes: [
                ...(nodeListDSL[parentNodeID].props.children as ReactNodePropValue)?.nodes,
                newNodeID,
              ],
            },
          },
        },
        [newNodeID]: newNodeDSL,
        ...childrenNodesList,
      },
      updateAliasForNodes: {
        [newNodeID]: newNodeDSL,
        ...childrenNodesList,
      },
      componentListDSL,
    }),
  };

  return {
    ...state,
    appConfiguration: {
      ...state.appConfiguration,
      appDSL: newUserAppDSL,
    },
  };
};

export const copyComponentToBuffer = (
  state: DashboardState,
  nodeID: NodeID[],
  currentPathName: string,
): DashboardState => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
  const stateListDSL = dashboardSelectors.getStateListDSL(state);
  const rootRouteNodeDSL = Object.values(nodeListDSL).filter(
    node => node.props.path === currentPathName,
  )[0] as NodeDSL;
  const localStateList = rootRouteNodeDSL.context as StateListDSL;

  const childrenIDs = nodeID
    .map(id =>
      nodeListSelectors.getAllChildrenIDs(nodeListDSL, {
        nodeID: id,
        componentListDSL,
      }),
    )
    .flat();

  const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID: nodeID[0],
  });

  const nestedStates = rootRouteNodeDSL?.states?.filter(states =>
    [...childrenIDs, targetNodeDSL.id, ...nodeID].includes(states.componentBoundID as string),
  );

  const targetNodeDSLs = nodeID.map(id =>
    nodeListSelectors.getNodeDSL(nodeListDSL, {
      nodeID: id,
    }),
  );

  const childrenNodes = nodeID
    .map(id =>
      nodeListSelectors.getAllChildrenNodes(nodeListDSL, {
        nodeID: id,
        componentListDSL,
      }),
    )
    .flat();

  const newNodes = targetNodeDSLs.map(nodeDSL => {
    const {
      nodeDSL: newNodeDSL,
      newStates,
      childrenNodes: newChildrenNodes,
    } = copyNodeWithChildren({
      nodeDSL,
      nodeListDSL,
      componentListDSL,
      stateListDSL: { ...stateListDSL },
      replacedStatesList: {},
      isCopySet: true,
    });
    Object.keys(newChildrenNodes).forEach(nodeKey => {
      delete (newChildrenNodes[parseInt(nodeKey)] as ClonedNodeDSL).oldID;
    });
    Object.keys(newStates).forEach(stateKey => {
      delete (newStates[stateKey] as ClonedStateDSL).oldID;
    });
    delete (newNodeDSL as ClonedNodeDSL).oldID;

    return {
      nodeDSL: newNodeDSL,
      newStates,
      childrenNodes: newChildrenNodes,
    };
  });

  const newStatesListWithIDs: StateListDSL = newNodes.reduce((accum, { newStates }) => {
    return {
      ...accum,
      ...reduceStateListWithIDs(newStates),
    };
  }, {});

  const childrenNodesList = childrenNodes
    .flat()
    .reduce((prev, current) => ({ ...prev, [current.id]: { ...current } }), {});

  const getTargetNodes = nodeID.map(node => nodeListDSL[node]);
  const neestedStatesIDs = nestedStates?.map(nestedState => nestedState.stateID);
  const statesCopyToBUffer: StateListDSL = Object.values(localStateList ?? {})
    .filter(localState => neestedStatesIDs?.includes(localState.id))
    .reduce((prev, current) => {
      return {
        ...prev,
        [current.id]: { ...current },
      };
    }, {});

  return {
    ...state,
    copyBuffer: {
      nodes: {
        ...childrenNodesList,
        ...getTargetNodes.reduce(
          (prev, current) => ({ ...prev, [current.id]: { ...current, parentID: null } }),
          {},
        ),
      },
      states: newStatesListWithIDs,
      nestedStates,
      currentPathName,
      statesCopyToBUffer,
    },
  };
};
